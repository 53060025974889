<template>
  <div>
    <div class="cell-group">
      <div class="cell-item">
        <div class="cell underline">
          <div class="label">标题</div>
          <input
            type="text"
            class="input"
            placeholder="请输入文章标题"
            v-model="form.title"
          />
        </div>
      </div>
      <div class="cell-item">
        <div class="cell underline">
          <div class="label">描述</div>
          <input
            type="text"
            class="input"
            placeholder="请输入文章描述"
            v-model="form.description"
          />
        </div>
      </div>
      <div class="cell-item">
        <div class="cell underline">
          <div class="label">选择开始日期</div>
          <input
            type="text"
            class="input"
            placeholder="请选择"
            v-model="form.beginDateTime"
            @click="dateShow = true"
          />
        </div>
      </div>
      <!-- 时间日期选择器 -->
      <div class="datePicker-box">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          v-show="dateShow"
          @cancel="dateShow = false"
          @confirm="comfirmDatePicker"
        />
      </div>
      <div class="cell-item">
        <div class="cell underline">
          <div class="label">选择结束日期</div>
          <input
            type="text"
            class="input"
            placeholder="请选择"
            v-model="form.endDateTime"
            @click="dateShowend = true"
          />
        </div>
      </div>
      <!-- 时间日期选择器 -->
      <div class="datePicker-box">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          v-show="dateShowend"
          @cancel="dateShowend = false"
          @confirm="comfirmDatePickerend"
        />
      </div>
      <div class="cell-item">
        <div class="cell underline">
          <input
            type="text"
            class="input-link"
            placeholder="请输入公众号文章链接"
            v-model="form.originalUrl"
          />
          <button class="btn-snyc" @click="tongbuHandle">同步</button>
        </div>
      </div>
      <div class="cell-item">
        <div class="flex-cloumn cell-upload underline">
          <div class="label padding-bottom-20" style="text-align: left">
            封面图
          </div>
          <van-uploader
            :after-read="afterReadCover"
            v-model="fileListCover"
            multiple="false"
            max-count="1"
            :before-delete="delectHandleCover"
            preview-size="160"
            image-fit="contain"
          />
          <div style="text-align: left" class="font-size-22">
            建议比例：146：146
          </div>
        </div>
      </div>
      <div class="cell-item">
        <div class="cell-link">
          <div class="label title" style="text-align: left">文章内容</div>
          <tinymce-editor
            ref="editor"
            v-model="form.content"
            :disabled="disabled"
            @onClick="onClick"
          >
          </tinymce-editor>
        </div>
      </div>

      <div class="cell-gap"></div>
      <div class="cell-title underline">分享</div>

      <div class="cell-item">
        <div class="cell underline">
          <div class="label">分享标题</div>
          <input
            type="text"
            class="input"
            placeholder="请输入分享标题"
            v-model="form.shareTitle"
          />
        </div>
      </div>
      <div class="cell-item">
        <div class="flex-cloumn cell-upload underline">
          <div class="label padding-bottom-20" style="text-align: left">
            分享图
          </div>
          <van-uploader
            :after-read="afterRead"
            v-model="fileList"
            multiple="false"
            max-count="1"
            :before-delete="delectHandle"
            preview-size="160"
            image-fit="contain"
          />
          <div style="text-align: left" class="font-size-22">
            建议比例：4：3
          </div>
        </div>
      </div>
      <div class="cell-item">
        <div class="cell-link">
          <div class="label title" style="text-align: left">文章摘要</div>
          <textarea
            type="text"
            class="textarea"
            placeholder="(最多120个字符)选填，摘要会在订阅号消息、转发链接等文章外的场景显露，帮助读者快速了解内容，如不填写则默认抓取正文前54字"
            v-model="form.shareDescription"
            maxlength="120"
          />
        </div>
      </div>
    </div>
    <button class="btn-submit" @click="addMethod" v-if="!id">立即发布</button>
    <button class="btn-submit" @click="editMethod" v-if="id">编辑</button>
  </div>
</template>

<script>
import TinymceEditor from "../components/tinymce-editor/tinymce-editor.vue";
import Vue from "vue";
import { DatetimePicker } from "vant";
import { Uploader } from "vant";
import { ref } from "vue";
import { add, update, get, uploadImg, getMpArticle } from "@/api/article";
var wx = require("weixin-js-sdk");
export default {
  components: {
    TinymceEditor,
  },

  data() {
    return {
      form: {
        title: "",
        originalUrl: "",
        coverImg: "",
        description: "",
        beginDateTime: "",
        endDateTime: "",
        content: "",
        shareTitle: "",
        shareCoverImg: "",
        shareDescription: "",
        shareUrl: "",
        acticleType: 0,
        isEnabled: true,
      },
      fileList: [],
      fileListCover: [],
      disabled: false,
      dateShow: false,
      dateShowend: false,
      currentDate: new Date(),
      id: "",
    };
  },
  created() {
    var that = this;
    var token = this.$route.query.token;
    var id = this.$route.query.id;
    that.id = id;
    console.log(id);
    if (id) {
      //是编辑
      that.getMethod();
    }
    localStorage.setItem("token", token);
  },
  methods: {
    formatterHandle: function (type, value) {
      var that = this;
      console.log(type, value);
    },
    tongbuHandle: function () {
      var that = this;
      var data = {
        url: that.form.originalUrl,
      };
      getMpArticle(data).then((response) => {
        that.form = response.response;
        that.form.acticleType = 0;
        let fileList = [{ url: response.response.entity.shareCoverImg }];
        that.fileList = fileList;
        let fileListCover = [{ url: response.response.entity.coverImg }];
        that.fileListCover = fileListCover;
        console.log(response);
      });
    },
    //获取信息接口
    getMethod: function () {
      var that = this;
      var data = {
        id: that.$route.query.id,
      };
      get(data).then((response) => {
        that.form = response.response.entity;
        var fileList = [{ url: response.response.entity.shareCoverImg }];
        that.fileList = fileList;
        let fileListCover = [{ url: response.response.entity.coverImg }];
        that.fileListCover = fileListCover;
      });
    },
    //发布接口
    addMethod: function () {
      var that = this;
      if (that.form.title == "") {
        that.Common.showMsg("请输入标题");
        return false;
      }
      if (that.form.beginDateTime == "") {
        that.Common.showMsg("请输入开始时间");
        return false;
      }
      if (that.form.endDateTime == "") {
        that.Common.showMsg("请输入结束时间");
        return false;
      }
      if (that.form.shareCoverImg == "") {
        that.Common.showMsg("请上传分享封面");
        return false;
      }
      if (that.form.shareDescription == "") {
        that.Common.showMsg("请输入分享描述");
        return false;
      }
      if (that.form.content == "") {
        that.Common.showMsg("请输入内容");
        return false;
      }
      var data = {
        title: that.form.title,
        originalUrl: that.form.originalUrl,
        coverImg: that.form.coverImg,
        description: that.form.description,
        beginDateTime: that.form.beginDateTime,
        endDateTime: that.form.endDateTime,
        content: that.form.content,
        shareTitle: that.form.shareTitle,
        shareCoverImg: that.form.shareCoverImg,
        shareDescription: that.form.shareDescription,
        shareUrl: that.form.shareUrl,
        acticleType: 0,
        isEnabled: true,
      };
      add(data).then((response) => {
        console.log(response);
        that.Common.showMsg("发布成功", function () {
          wx.miniProgram.navigateBack({
            delta: 1,
            fail: function () {
              wx.miniProgram.switchTab({
                url: "/pages/contentCenter/contentList/contentList",
              });
            },
          });
        });
      });
    },
    //编辑接口
    editMethod: function () {
      var that = this;
      var data = that.form;
      data.id = that.$route.query.id;
      update(data).then((response) => {
        console.log(response);
        console.log(wx);
        that.Common.showMsg("编辑成功", function () {
          wx.miniProgram.navigateBack({
            delta: 1,
            fail: function () {
              wx.miniProgram.switchTab({
                url: "/pages/contentCenter/contentList/contentList",
              });
            },
          });
        });
      });
    },
    afterRead(file) {
      var that = this;
      // 此时可以自行将文件上传至服务器
      var formData = new FormData();
      formData.append("file", file.file);
      var data = formData;
      uploadImg(data).then((response) => {
        console.log(response);
        that.form.shareCoverImg = response.data;
        console.log(that.form.shareUrl);
        console.log(that.fileList);
      });
    },
    delectHandle: function () {
      var that = this;
      that.form.shareCoverImg = "";
      that.fileList = [];
    },
    afterReadCover(file) {
      var that = this;
      // 此时可以自行将文件上传至服务器
      var formData = new FormData();
      formData.append("file", file.file);
      var data = formData;
      uploadImg(data).then((response) => {
        console.log(response);
        that.form.coverImg = response.data;
        console.log(that.form.coverImg);
      });
    },
    delectHandleCover: function () {
      var that = this;
      that.form.coverImg = "";
      that.fileListCover = [];
    },
    comfirmDatePickerend(val) {
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let hour = val.getHours();
      let minute = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (hour >= 0 && hour <= 9) {
        hour = `0${hour}`;
      }
      if (minute >= 0 && minute <= 9) {
        minute = `0${minute}`;
      }
      this.form.endDateTime = `${year}-${month}-${day}`;
      this.dateShowend = false;
    },
    // 点击了确定开始时间
    comfirmDatePicker(val) {
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let hour = val.getHours();
      let minute = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (hour >= 0 && hour <= 9) {
        hour = `0${hour}`;
      }
      if (minute >= 0 && minute <= 9) {
        minute = `0${minute}`;
      }
      this.form.beginDateTime = `${year}-${month}-${day}`;
      this.dateShow = false;
    },
    // 鼠标单击的事件
    onClick(e, editor) {
      console.log("Element clicked");
      console.log(e);
      console.log(editor);
    },
    sureHandle() {
      var that = this;
      console.log(that.msg);
    },
    // 清空内容
    clear() {
      this.$refs.editor.clear();
    },
  },
};
</script>

<style scoped>
.cell-group {
  box-sizing: border-box;
  padding: 30px 0;
}
.cell-item {
  box-sizing: border-box;
  padding: 0 28px;
  width: 100%;
  font-size: 28px;
}

.flex-cloumn {
  display: flex;
  flex-direction: column;
}

.cell-gap {
  width: 100%;
  height: 24px;
  background-color: #f7f7f7;
}

.cell {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 32px 0;
}

.cell-upload {
  box-sizing: border-box;
  padding: 32px 0;
}
.cell-link {
  box-sizing: border-box;
  padding: 32px 0;
}
.input-link {
  border: none;
  font-size: 26px;
  background-color: #f4f5f5;
  /* width: 148px; */
  height: 58px;
  flex: 1;
  padding-left: 40px;
  border-radius: 30px 0 0 30px;
}

.btn-snyc {
  background-color: #4995f0;
  color: #ffffff;
  font-size: 28px;
  border-radius: 0 30px 30px 0;
  border: none;
  width: 148px;
  height: 58px;
}

.underline {
  border-bottom: 1px solid #ebedf0;
}

.label {
  font-size: 28px;
}

.input {
  border: none;
  margin-left: 58px;
  flex: 1;
}

.cell-title {
  box-sizing: border-box;
  padding: 28px;
  text-align: left;
  font-size: 32px;
  font-weight: 700;
}

.textarea {
  width: 618px;
  height: 157px;
  margin-top: 24px;
  font-size: 28px;
  box-sizing: border-box;
  padding: 10px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.font-size-22 {
  font-size: 22px;
}

.btn-submit {
  width: 500px;
  height: 80px;
  background-color: #4995f0;
  border-radius: 50px;
  margin: 15px 0 30px;
  color: #ffffff;
  border: none;
  font-size: 32px;
}

.datePicker-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}
</style>
