import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "文章发布",
    },
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: "授权登录",
    },
    component: () => import("../views/userCenter/login"),
  },
  {
    path: "/author",
    name: "author",
    meta: {
      title: "授权",
    },
    component: () => import("../views/userCenter/author"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
